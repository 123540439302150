import { displayErrorMessages } from '@services/errorHandler'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

// eslint-disable-next-line import/prefer-default-export
export function useTARDashboardQuery(patientId, dateRange, type, statusMessage) {
  const query = useQuery({
    queryKey: ['tar', patientId, dateRange, type],
    queryFn: () => {
      const params = {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
        type,
      }
      return ApiClient.get(`/patients/${patientId}/tar_dashboard`, { params })
    },
    onError: (error) => statusMessage.current && displayErrorMessages(error, statusMessage),
  })

  return query
}
