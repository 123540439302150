import React, {
  memo, useRef, useState, useMemo,
} from 'react'
import { OverlayPanel } from 'primereact/overlaypanel'
import moment from 'moment'
import { useCurrentUser } from '@components/App'
import { generateFormattedDateTimeString } from '@services/utils'
import DateTable from '@components/display/DateTable/DateTable'
import DoseStatusDialog from '../DoseStatusDialog'
import { defaultTimeFormat, defaultTimeFormatWithSeconds } from '../../../../display/Calendar/calendarUtils'
import StatusIndicator from './StatusIndicator'
import { generateDateColumns } from './scheduledDosesUtils'
import AdherenceTableHeader from '../AdherenceTableHeader'
import MarSigDetails from '../MarSigDetails'
import MARApprovalsTable from '../MARApproval/MARApprovalTable'
import StatusOverlay from '../StatusOverlay'
import ApprovalOverlay from '../MARApproval/ApprovalOverlay'

function MedicineDetails({ rowData }) {
  return (
    <div className="flex flex-column gap-1 align-items-start text-left">
      <span style={{ wordBreak: 'break-all' }}>
        {rowData.medicine}
      </span>
    </div>
  )
}

const ScheduledConsumptions = memo(({
  data,
  dateRange,
  isLoading,
  timezone,
  patientId,
  marApprovals,
  isApprovalsLoading,
  startDate,
  widthForDayCount,
}) => {
  const consumptionOverlay = useRef(null)
  const dateOverlay = useRef(null)
  const [selectedCell, setSelectedCell] = useState({})
  const [dateHovered, setDateHovered] = useState()
  const currentUser = useCurrentUser()
  const [doseStatusDialogMode, setDoseStatusDialogMode] = useState()
  const isAdmin = currentUser.role === 'site_admin'
  const dateMarApprovals = dateHovered ? marApprovals.filter((mar) => mar.days.includes(dateHovered.format('YYYY-MM-DD'))) : []

  const showConsumptionOverlay = (consumption, e) => {
    setSelectedCell(consumption)
    if (consumptionOverlay.current) {
      consumptionOverlay.current.hide()
      requestAnimationFrame(() => {
        consumptionOverlay.current.show(e)
      })
    } else {
      consumptionOverlay.current.show(e)
    }
  }

  const showDateOverlay = (date, e) => {
    setDateHovered(date)
    if (dateOverlay.current) {
      dateOverlay.current.hide()
      requestAnimationFrame(() => {
        dateOverlay.current.show(e)
      })
    } else {
      dateOverlay.current.show(e)
    }
  }

  const hideDateOverlay = () => {
    if (dateOverlay.current) {
      dateOverlay.current.hide()
    }
  }

  const onEmptyCellClick = (rowData, indexOfDay, e) => {
    const date = startDate.clone().add(indexOfDay, 'days')
    const selectedTime = moment(rowData.time, defaultTimeFormat)
      .format(defaultTimeFormatWithSeconds)
    const selectedDate = moment.tz(date, timezone)
    const consumptionExpectedDate = generateFormattedDateTimeString(selectedDate, selectedTime)

    setSelectedCell({
      dose: rowData.dose, doseSig: rowData.doseSig, expectedAt: consumptionExpectedDate, event: e, status: 'empty',
    })
    setDoseStatusDialogMode('add')
  }

  const statusTemplate = (
    rowData,
    indexOfDay,
  ) => {
    const dayData = rowData.days[indexOfDay]
    return (
      <StatusIndicator
        rowData={rowData}
        indexOfDay={indexOfDay}
        dayData={dayData}
        isAdmin={isAdmin}
        onEmptyCellClick={onEmptyCellClick}
        showOverlay={showConsumptionOverlay}
      />
    )
  }

  const headerTemplate = (date, formattedDate, approvals) => (
    <AdherenceTableHeader
      date={date}
      formattedDate={formattedDate}
      approvals={approvals}
      showDateOverlay={showDateOverlay}
      hideDateOverlay={hideDateOverlay}
    />
  )

  const medicineTemplate = (rowData) => (
    <MedicineDetails rowData={rowData} />
  )

  const detailsTemplate = (rowData) => {
    const { doseSig } = rowData
    return (
      <MarSigDetails
        doseSig={doseSig}
      />
    )
  }

  const timeTemplate = (rowData) => rowData.time

  const dateColumns = useMemo(
    () => generateDateColumns({
      dateRange,
      marApprovals,
      statusTemplate,
      headerTemplate,
      widthForDayCount,
      fieldPrefix: 'consumptions.',
    }),
    [dateRange, statusTemplate],
  )

  const columns = [
    {
      field: 'medicine', header: 'Medicine', style: { minWidth: '260px', width: '260px', maxWidth: '260px' }, body: medicineTemplate,
    },
    {
      field: 'doseSig.id', header: 'Details', style: { minWidth: '240px', width: '240px', maxWidth: '240px' }, body: detailsTemplate,
    },
    {
      field: 'time', header: 'Expected Time', style: { minWidth: '150px', width: '150px', maxWidth: '150px' }, body: timeTemplate,
    },
    ...dateColumns.map((column) => ({
      ...column,
    })),
  ]

  return (
    <div className="datatable-container flex flex-column">
      <div className="flex flex-column gap-3 text-base text-900" style={{ overflowX: 'auto' }}>
        <DateTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          tableClassName="dose-consumptions"
          emptyMessage="No medication available"
          className="schedule-mar-view"
          sortField="medicine"
          rowGroupMode="rowspan"
          groupRowsBy={['medicine', 'doseSig.id']}
          sortMode="single"
        />
        <MARApprovalsTable
          marApprovals={marApprovals}
          isLoading={isApprovalsLoading}
        />
      </div>
      <OverlayPanel ref={consumptionOverlay} className="dose-overlay">
        <StatusOverlay
          selectedCell={selectedCell}
          canEdit={isAdmin}
          showEditStatusDialog={() => setDoseStatusDialogMode('edit')}
          overlayType={selectedCell.status}
        />
      </OverlayPanel>
      <OverlayPanel ref={dateOverlay} className="date-overlay">
        <ApprovalOverlay marApprovals={dateMarApprovals} />
      </OverlayPanel>
      {
        (currentUser.role === 'site_admin' || currentUser.role === 'caregiver') && (
          <DoseStatusDialog
            visible={!!doseStatusDialogMode}
            onHide={() => setDoseStatusDialogMode()}
            selectedCell={selectedCell}
            timezone={timezone}
            mode={doseStatusDialogMode}
            patientId={patientId}
          />
        )
      }
    </div>
  )
})

export default ScheduledConsumptions
