import React, {
  useRef, useMemo, useState, useEffect,
} from 'react'
import moment from 'moment'
import { useForm, Controller } from 'react-hook-form'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import './prnDoses.scss'
import { InputTextarea } from 'primereact/inputtextarea'
import { useCurrentOrganization } from '@components/App'
import { useCanAdministerConsumption } from '@services/hooks'
import { groupedUsersByRole, roleToOrganizationLabel } from '@services/utils'
import { useCreatePatientConsumptionMutation, useUpdatePatientConsumptionMutation } from '../../../../../features/Patients/patientHooks'
import { createConsumptionPayload, buildDateAndTimeWithTimeZone, convertToOrgTimezone } from './prnConsumptionUtils'
import { refusedReasonArray } from '../../adherenceDashboardUtils'

function FormFieldError({ error }) {
  return error && <small className="p-error">{error.message}</small>
}

function PRNDoseFormModal({
  visible,
  onHide,
  dose,
  doseSig,
  selectedDate,
  patientId,
  mode,
  consumptionToBeUpdated,
  setConsumptionToBeUpdated,
}) {
  const {
    currentConfirmAt, consumptionId, prnReason, reason, note,
  } = consumptionToBeUpdated || {}
  const [confirmedAt, setConfirmedAt] = useState(currentConfirmAt)
  const statusMessage = useRef(null)
  const calendarRef = useRef(null)
  const organization = useCurrentOrganization()
  const isAddingPrn = mode === 'add'
  const isOverriding = mode === 'edit'
  const addPrnDoseDefaults = {
    administeredBy: null,
    status: null,
    time: new Date(moment.tz(organization.timezone).format('YYYY-MM-DDTHH:mm:ss')),
    dateTime: new Date(moment.tz(currentConfirmAt, organization.timezone).format('YYYY-MM-DDTHH:mm:ss')),
    prnReason: prnReason || '',
    reason: reason || '',
    note: note || '',
  }

  const {
    handleSubmit, control, formState, watch,
  } = useForm({ defaultValues: addPrnDoseDefaults })

  const { errors } = formState
  const statusValue = watch('status')

  const {
    mutateAsync: createPRNConsumption,
    isLoading: createIsLoading,
  } = useCreatePatientConsumptionMutation(statusMessage)

  const {
    mutateAsync: updatePRNConsumption,
    isLoading: updateIsLoading,
  } = useUpdatePatientConsumptionMutation(consumptionId, statusMessage)

  const onTimeSelect = (value) => {
    if (!value) return
    calendarRef.current?.hide()

    const confirmedDateTime = isAddingPrn
      ? buildDateAndTimeWithTimeZone({
        date: selectedDate, time: value, timezone: organization.timezone,
      })
      : convertToOrgTimezone(value, organization.timezone)
    setConfirmedAt(
      confirmedDateTime,
    )
  }
  useEffect(() => {
    if (isAddingPrn) {
      const currentDateTime = moment.tz(organization.timezone).toDate()
      setConfirmedAt(currentDateTime)
    } else if (isOverriding) {
      setConfirmedAt(currentConfirmAt)
    }
  }, [isAddingPrn, isOverriding, currentConfirmAt, organization.timezone])

  const { data: users, isLoading: usersLoading } = useCanAdministerConsumption(
    {
      statusMessage,
      patientId,
      willConfirmAt: confirmedAt,
      prn: true,
    },
  )

  const statusOptions = [
    { value: 'CREATE_CONFIRMED', label: 'Given' },
    { value: 'CREATE_REFUSED', label: 'Refused' },
  ]

  const administeredByOptions = useMemo(
    () => groupedUsersByRole(users, organization),
    [users, organization],
  )

  const administeredByOptionTemplate = (option) => (
    <div>
      <span>{option.fullName}</span>
      <span className="text-xs ml-1 text-500">
        (
        {roleToOrganizationLabel(option.role, organization, { singular: true }) || 'No Role'}
        )
      </span>
      {
        option.currentUserActivation.deactivatedAt && (
          <span className="text-xs ml-1 text-red-500">Deactivated</span>
        )
      }
    </div>
  )
  const administeredByTemplate = (option, props) => {
    if (option) {
      return administeredByOptionTemplate(option)
    }
    return <span>{props.placeholder}</span>
  }

  const onSubmit = async (formData) => {
    const payload = createConsumptionPayload({
      formData, isAddingPrn, isOverriding, selectedDate, organization, dose, doseSig, patientId,
    })
    const action = isAddingPrn ? createPRNConsumption : updatePRNConsumption

    await action(payload, {
      onSuccess: () => {
        onHide()
        setConsumptionToBeUpdated({})
        setConfirmedAt(null)
      },
    })
  }

  const clockIcon = <i className="pi pi-clock" />
  const header = (
    <div className="flex flex-column align-content-start gap-2">
      <span className="font-bold text-xl">{isAddingPrn ? 'Add PRN Consumption' : 'Edit Confirmation'}</span>
      <span className="font-normal line-height-2 text-sm">{isAddingPrn ? 'Please enter the details of the consumption' : 'Please select the name of the person to confirm'}</span>
    </div>
  )

  const shouldDisableAdministeredBy = usersLoading || !users.length

  return (
    <Dialog header={header} className="w-30rem sm:20rem" visible={visible} onHide={onHide} draggable={false}>
      <Toast ref={statusMessage} />
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column w-full gap-4">
        <div className="flex flex-row flex-wrap justify-content-between gap-3">
          <div className="flex flex-column align-content-start gap-2">
            <label htmlFor="status">Status</label>
            <Controller
              name="status"
              control={control}
              rules={{ required: 'Status is required' }}
              render={({ field }) => (
                <>
                  <Dropdown
                    id="status"
                    className="h-3rem"
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={statusOptions}
                    optionLabel="label"
                    placeholder="Select Status"
                  />
                  <FormFieldError error={errors.status} />

                </>
              )}
            />
          </div>
          {isAddingPrn && (
          <div className="flex flex-column align-content-start gap-2">
            <label htmlFor="time">Time</label>
            <Controller
              name="time"
              control={control}
              rules={{ required: 'Time is required' }}
              render={({ field }) => (
                <div className="flex flex-column" onClick={() => calendarRef.current.focus()}>
                  <Calendar
                    ref={calendarRef}
                    id="time"
                    className="h-3rem border-round-md"
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    timeOnly
                    hourFormat="12"
                    showIcon
                    icon={clockIcon}
                    onBlur={() => onTimeSelect(field.value)}
                  />
                  <FormFieldError error={errors.time} />
                </div>
              )}
            />
          </div>
          )}
          {isOverriding && (
          <div className="flex flex-column align-content-start gap-2">
            <label htmlFor="dateTime">Date</label>
            <Controller
              name="dateTime"
              control={control}
              rules={{ required: 'Date and Time is required' }}
              render={({ field }) => (
                <>
                  <Calendar
                    id="dateTime"
                    className="h-3rem border-round-md"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value)
                      setConfirmedAt(e.value)
                    }}
                    showTime
                    hourFormat="12"
                    showIcon
                    icon={clockIcon}
                  />
                  <FormFieldError error={errors.dateTime} />
                </>
              )}
            />
          </div>
          )}
        </div>
        <div className="flex flex-column align-content-start w-full gap-2">
          <label htmlFor="administeredBy">Administered By</label>
          <Controller
            name="administeredBy"
            control={control}
            rules={{ required: 'Administered By is required' }}
            render={({ field }) => (
              <>
                <Dropdown
                  id="administeredBy"
                  className="h-3rem"
                  value={field.value}
                  options={administeredByOptions}
                  optionGroupLabel="role"
                  optionGroupChildren="users"
                  optionLabel="fullName"
                  valueTemplate={administeredByTemplate}
                  itemTemplate={administeredByOptionTemplate}
                  loading={usersLoading}
                  disabled={shouldDisableAdministeredBy}
                  onChange={(e) => field.onChange(e.value)}
                  placeholder={`${(shouldDisableAdministeredBy)
                    ? 'Select time to load users'
                    : 'Select a user'}`}
                />
                <FormFieldError error={errors.administeredBy} />
              </>
            )}
          />
        </div>
        <div className="flex flex-column align-content-start gap-2">
          <label htmlFor="prnReason">
            PRN Reason
            {!dose.prnReasonRequired && (
            <span className="text-sm text-gray-400"> (Optional)</span>
            )}
          </label>
          <Controller
            name="prnReason"
            control={control}
            rules={{ required: dose.prnReasonRequired ? 'PRN Reason is required' : undefined }}
            render={({ field }) => (
              <>
                <InputTextarea
                  id="prnReason"
                  {...field}
                />
                <FormFieldError error={errors.prnReason} />
              </>
            )}
          />
        </div>
        {(statusValue === 'CREATE_REFUSED' && (
        <div className="flex flex-column align-content-start gap-2">
          <label htmlFor="reason">
            Reason
          </label>
          <Controller
            name="reason"
            control={control}
            rules={{ required: 'Refused reason is required' }}
            render={({ field }) => (
              <>
                <Dropdown
                  value={field.value}
                  options={refusedReasonArray(organization.patientLabelSingular)}
                  onChange={(e) => field.onChange(e.value)}
                  placeholder="Please select a reason"
                  className="w-full"
                />
                <FormFieldError error={errors.reason} />
              </>
            )}
          />
        </div>
        )
          )}
        <div className="flex flex-column align-content-start gap-2">
          <label htmlFor="note">Notes</label>
          <Controller
            name="note"
            control={control}
            rules={{ required: 'Notes is required' }}
            render={({ field }) => (
              <>
                <InputTextarea
                  id="note"
                  {...field}
                />
                <FormFieldError error={errors.note} />

              </>
            )}
          />
        </div>
        <div className="flex w-full justify-content-center">
          <Button type="submit" label="Save" className="p-button-sm w-full mt-2">
            {(createIsLoading || updateIsLoading) && <i className="pi pi-spin pi-spinner text-lg" />}
          </Button>
        </div>
      </form>
    </Dialog>
  )
}

export default PRNDoseFormModal
