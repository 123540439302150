import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from 'moment'

function statusStyle(col, rowData, statusStyles) {
  // identifies the dynamic field prefix
  const fieldPrefix = col.field.match(/^[^.]+/)[0]
  const columnKey = col.field.replace(`${fieldPrefix}.`, '')

  // finds the index of the day where the dueAt date matches the column date
  // future chore: change dueAt to a prop to make it more dynamic
  const dayIndex = rowData.days.findIndex((day) => moment(columnKey, 'MMM-Do').isSame(moment(day.dueAt), 'day'))

  const dayData = rowData.days[dayIndex]

  // returns the status style based on the day status and applies it to the cell
  return statusStyles[dayData?.status]
}

function DateTable({
  columns, data, isLoading, tableClassName, emptyMessage, className, sortField,
  rowGroupMode, groupRowsBy, sortMode, statusStyles,
}) {
  return (
    <DataTable
      value={data}
      rowGroupMode={rowGroupMode}
      groupRowsBy={groupRowsBy}
      sortMode={sortMode}
      sortField={sortField}
      showGridlines
      scrollable
      tableClassName={tableClassName}
      loading={isLoading}
      className={className}
      emptyMessage={emptyMessage}
    >
      {columns.map((col, index) => (
        <Column
          key={index}
          field={col.field}
          header={col.header}
          body={col.body || null}
          style={{ ...col.style }}
          bodyClassName={(rowData) => {
            if (!statusStyles) return null
            return statusStyle(col, rowData, statusStyles)
          }}
        />
      ))}
    </DataTable>
  )
}

export default DateTable
