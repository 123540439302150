import {
  flatMap, groupBy, isEmpty, isNumber,
} from 'lodash'
import { RRule } from 'rrule'
import { timeTemplate, titleCase } from '@services/utils'
import pluralize from 'pluralize'
import moment from 'moment'

const formatAdministrationAmount = ({ administrationAmount, units, medicine = {} }) => {
  const measurementUnit = units || medicine.units

  const parts = [administrationAmount, measurementUnit]
    .filter((part) => isNumber(part) || !isEmpty(part))
  return parts.join(' ')
}

// TODO: More callers should pass the dose unit instead of relying on the medicine's derived units
const formatDoseAmount = ({ administrationAmount, units, medicine = {} }) => {
  const medicineForm = isEmpty(medicine.form) ? null
    : pluralize(medicine.form, administrationAmount)

  if (medicine.originCode === 'pharmacy_integration') {
    const measurementUnit = units || medicine.units

    const parts = [administrationAmount, measurementUnit, medicineForm]
      .filter((part) => isNumber(part) || !isEmpty(part))
    return parts.join(' ')
  }

  const parts = [administrationAmount, medicineForm]
    .filter((part) => isNumber(part) || !isEmpty(part))
  return parts.join(' ')
}

const medicineDisplayName = (dose) => {
  if (!isEmpty(dose.orderText)) {
    return dose.orderText
  }
  return dose.medicine.name
}

const hasDoseEnded = (dose) => {
  if (!dose.endAt) {
    return false
  }
  const endAt = moment(dose.endAt)
  const now = moment()
  return endAt.isBefore(now)
}

const medicineDisplayNameWithStrength = (dose) => {
  if (!isEmpty(dose.orderText)) {
    return dose.orderText
  }
  return `${dose.medicine.name}, ${dose.medicine.strength}`
}

const calculateDoseStatus = (dose) => {
  let color; let text; let
    order
  if (dose.activatedAt) {
    if (dose.currentPause) {
      color = 'var(--bluegray-300)'
      text = 'On Hold'
      order = 4
    } else {
      color = 'var(--green-400)'
      text = 'Active'
      order = 2
    }
  }

  if (!dose.activatedAt) {
    color = 'var(--yellow-400)'
    text = 'In Review'
    order = 0
  }

  if (dose.issueReportedAt) {
    color = 'var(--orange-400)'
    text = 'Issue Raised'
    order = 1
  }

  // If it's archived and ended, status should be archived
  if (dose.archivedAt) {
    color = 'var(--bluegray-300)'
    text = 'Archived'
    order = 3
  } else if (hasDoseEnded(dose)) {
    color = 'var(--bluegray-300)'
    text = 'Ended'
    order = 5
  }

  return { color, text, order }
}

const calculateDoseType = (dose) => {
  const doseType = dose.doseType ?? (dose.prn ? 'prn' : 'routine')
  return { color: 'var(--blue-400)', text: doseType }
}

const isPrn = (dose, doseSig = null) => {
  if (typeof doseSig?.prn === 'boolean') {
    return doseSig.prn
  }

  return dose?.doseType === 'prn'
}

const hasPrnSig = (dose) => {
  if (!dose) {
    return false
  }

  if (typeof dose.doseType === 'string') {
    return ['prn', 'prn+'].includes(dose.doseType)
  }

  return typeof dose.prn === 'boolean' ? dose.prn : false
}

const hasScheduleSig = (dose) => {
  if (!dose) {
    return false
  }

  if (typeof dose.doseType === 'string') {
    return ['loading', 'prn+', 'routine', 'titration'].includes(dose.doseType)
  }

  return typeof dose.prn === 'boolean' ? !dose.prn : false
}

/**
 * Checks if the given dose has any sigs with schedules
 * @param {object} dose
 * @returns {boolean}
 */
const hasSchedules = (dose) => {
  if (dose && Array.isArray(dose.sigs)) {
    return dose.sigs.some((sig) => Array.isArray(sig.schedules) && sig.schedules.length > 0)
  }

  return false
}

const formattedTextsFromSchedules = (schedules) => {
  const schedulesByRruleText = groupBy(schedules, (schedule) => {
    const { rrule } = schedule
    const parsedRrule = RRule.fromString(rrule)
    return `${parsedRrule.options.freq}`
  })

  const formattedScheduleTexts = flatMap(schedulesByRruleText, (schedulesByRrule) => {
    const { rrule } = schedulesByRrule[0]
    const parsedRrule = RRule.fromString(rrule)

    const times = schedulesByRrule.map((schedule) => {
      const { time } = schedule
      const formattedTime = timeTemplate(time)
      return formattedTime
    })

    return `${titleCase(parsedRrule.toText())} at ${times.join(', ')}`
  })

  return formattedScheduleTexts
}

export {
  formatDoseAmount,
  formatAdministrationAmount,
  calculateDoseStatus,
  calculateDoseType,
  hasDoseEnded,
  hasPrnSig,
  hasScheduleSig,
  hasSchedules,
  isPrn,
  medicineDisplayName,
  medicineDisplayNameWithStrength,
  formattedTextsFromSchedules,
}
