import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export const useTask = ({ taskId }) => {
  const query = useQuery({
    queryKey: ['task', taskId],
    queryFn: () => ApiClient.get(`/tasks/${taskId}`),
    enabled: !!taskId,
  })

  return useMemo(() => ({
    ...query,
    data: query.data || {},
  }), [query])
}

export default useTask
