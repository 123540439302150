import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import SiteDashboard from '@components/sites/SiteDashboard'
import { compact, get } from 'lodash'
import { useSite } from '@services/hooks/sitesHooks'
import UpdateSite from './UpdateSite'
import UpsertWifi from './UpsertWifi'
import EventTimes from '../Schedules/EventTimes'
import NarcoticCounts from './NarcoticCounts'

function Site({ useSiteHeader }) {
  const { siteId } = useParams()
  const { setHeader } = useSiteHeader()
  const { data: site, isLoading } = useSite(siteId)
  const currentUser = useCurrentUser()
  const currentOrganization = useCurrentOrganization()
  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)

  const title = {
    label: (
      <span className="flex flex-row align-items-center" id="patient-name-tab">
        <i className="text-xl pi pi-user mr-2" />
        {isLoading && (
          <Skeleton className="inline-flex" width="10rem" height="1.5rem" />
        )}
        {!isLoading && (
          <span className="fadein">
              {`${get(site, 'name')}`}
          </span>
        )}
      </span>
    ),
    linkTo: `/admin/sites/${siteId}`,
  }

  const tabs = compact([
    {
      label: 'Scheduled Events',
      linkTo: `${siteId}/event_times`,
    },
    {
      label: 'Wi-Fi',
      linkTo: `${siteId}/wifi`,
    },
    currentOrganization.narcoticCountsEnabled
    && {
      label: 'Narcotic Counts',
      linkTo: `${siteId}/narcotic_counts`,
    },
  ])

  const breadcrumbs = [
    {
      label: 'Site',
      template: <span>Site</span>,
    },
  ]

  const handleSetHeader = (props = {}) => {
    setHeader({
      title, tabs, breadcrumbs, ...props,
    })
  }

  return (
    <Routes>
      <Route
        index
        element={(
          <SiteDashboard
            isCurrentUserAdmin={isCurrentUserAdmin}
            siteId={siteId || currentUser.id}
            handleSetHeader={handleSetHeader}
            siteLoading={isLoading}
            site={site}
          />
        )}
      />
      <Route
        path="update"
        element={<UpdateSite site={site} handleSetHeader={handleSetHeader} />}
      />
      <Route
        path="event_times/*"
        element={<EventTimes ownerType="Site" handleSetHeader={handleSetHeader} />}
      />
      <Route
        path="wifi"
        element={<UpsertWifi handleSetHeader={handleSetHeader} />}
      />
      <Route
        path="narcotic_counts"
        element={(
          <NarcoticCounts
            handleSetHeader={handleSetHeader}
          />
      )}
      />
    </Routes>
  )
}

export default Site
