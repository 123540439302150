import React, { useState } from 'react'
import {
  map, sortBy,
} from 'lodash'
import moment from 'moment/moment'
import {
  AdministrationAmountEditor, DateEditor, DoseAttributeRow, EditButton,
} from '@components/DoseAttributeRow'
import { AddTimeEditor, PrnEditor, ScheduleEditor } from '@components/pharmacyOrders/Editors'
import { momentFormats, momentFromMomentDateAndTime } from '@services/utils/moment'
import { formatAdministrationAmount } from '@components/clientDoses/doseUtils'
import {
  useReviewableOrderHandlers,
  useReviewableOrderState,
} from '../../../../features/ClientDoses/PharmacyOrders/reviewableOrderState/ReviewableOrderContext'

function SigFieldGroup({ compositeOrderSig }) {
  const [editAttribute, setEditAttribute] = useState(null)

  const {
    handleAddSchedule,
    handleDeleteSchedule,
    handleSetPrn,
    handleUpdateAdministrationAmount,
    handleUpdateEndAt,
    handleUpdatePharmacyInstructions,
    handleUpdateSchedule,
    handleUpdateStartAt,
    initializeSchedules,
  } = useReviewableOrderHandlers()

  const { medicine, sigs } = useReviewableOrderState()

  const stateSig = sigs?.find((sig) => sig.compositeOrderSigId === compositeOrderSig.id)

  if (!stateSig) {
    return null
  }

  const {
    administrationAmount,
    id: sigId,
    pharmacyInstructions,
    prn,
    schedules,
    startAt,
    endAt,
  } = stateSig

  // end at can only be in the future and after start at in case start is also in the future
  let minEndAt = startAt || moment().startOf('day')
  const tomorrowStart = moment().add(1, 'day').startOf('day')
  if (minEndAt.isBefore(tomorrowStart)) {
    minEndAt = tomorrowStart
  }

  const sigAttributes = [
    {
      label: 'Instructions',
      order: 2,
      currentValue: pharmacyInstructions,
      newValue: compositeOrderSig?.pharmacyInstructions,
      valueChangeHandler: () => (
        handleUpdatePharmacyInstructions({
          id: sigId,
          pharmacyInstructions: compositeOrderSig?.pharmacyInstructions,
        })
      ),
    },
    {
      label: 'Administration Amount',
      order: 4,
      newValue: formatAdministrationAmount({
        administrationAmount: compositeOrderSig?.administrationAmount,
        units: compositeOrderSig?.units,
        medicine,
      }),
      currentValue: formatAdministrationAmount({
        administrationAmount,
        units: compositeOrderSig?.units,
        medicine,
      }),
      valueChangeHandler: () => (
        handleUpdateAdministrationAmount({
          id: sigId,
          administrationAmount: compositeOrderSig?.administrationAmount,
        })
      ),
      editor: (
        <AdministrationAmountEditor
          medicine={medicine}
          units={compositeOrderSig?.units}
          administrationAmount={administrationAmount}
          setAdministrationAmount={(value) => {
            handleUpdateAdministrationAmount({ id: sigId, administrationAmount: value })
            setEditAttribute(null)
          }}
        />
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'administrationAmount' ? setEditAttribute(null) : setEditAttribute('administrationAmount'))}
          isEditing={editAttribute === 'administrationAmount'}
        />
      ),
      isEditing: editAttribute === 'administrationAmount',
    },
    {
      label: 'Start Date',
      order: 6,
      newValue: compositeOrderSig?.startAt ? compositeOrderSig?.startAt.format(momentFormats.monthDay) : '',
      currentValue: startAt ? startAt.format(momentFormats.monthDay) : '',
      valueChangeHandler: () => (
        handleUpdateStartAt({ id: sigId, startAt: compositeOrderSig?.startAt })
      ),
      editor: (
        <DateEditor
          momentDate={startAt}
          max={endAt ? moment(endAt) : null}
          setMomenDate={(value) => {
            const timeMoment = startAt || moment().startOf('day')
            handleUpdateStartAt({
              id: sigId,
              startAt: momentFromMomentDateAndTime(value, timeMoment),
            })
            setEditAttribute(null)
          }}
        />
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'startAt' ? setEditAttribute(null) : setEditAttribute('startAt'))}
          isEditing={editAttribute === 'startAt'}
        />
      ),
      isEditing: editAttribute === 'startAt',
    },
    {
      label: 'End Date',
      order: 7,
      newValue: compositeOrderSig?.endAt ? compositeOrderSig?.endAt.format(momentFormats.monthDay) : '',
      currentValue: endAt ? endAt.format(momentFormats.monthDay) : '',
      valueChangeHandler: () => (
        handleUpdateEndAt({ id: sigId, endAt: compositeOrderSig?.endAt })
      ),
      editor: (
        <DateEditor
          momentDate={endAt}
          min={minEndAt}
          setMomenDate={(value) => {
            const timeMoment = endAt || moment().endOf('day')
            handleUpdateEndAt({
              id: sigId,
              endAt: momentFromMomentDateAndTime(value, timeMoment),
            })
            setEditAttribute(null)
          }}
        />
      ),
      action: (
        <EditButton
          onClick={() => (editAttribute === 'endAt' ? setEditAttribute(null) : setEditAttribute('endAt'))}
          isEditing={editAttribute === 'endAt'}
        />
      ),
      isEditing: editAttribute === 'endAt',
    },
    {
      label: 'PRN',
      order: 8,
      newValue: compositeOrderSig?.prn ? 'YES' : 'NO',
      currentValue: prn ? 'YES' : 'NO',
      valueChangeHandler: () => (
        handleSetPrn({ id: sigId, prn: compositeOrderSig?.prn })
      ),
      action: (
        <PrnEditor prn={prn} setPrn={(value) => handleSetPrn({ id: sigId, prn: value })} />
      ),
    },
    {
      label: 'Schedule',
      order: 9,
      currentValue: (
        <ScheduleEditor
          orderSchedules={compositeOrderSig?.schedules}
          invalidSchedules={compositeOrderSig?.invalidSchedules}
          schedules={schedules}
          initializeSchedules={initializeSchedules(sigId)}
          handleAddSchedule={({ schedule }) => handleAddSchedule(sigId)(schedule)}
          handleUpdateSchedule={({ schedule }) => handleUpdateSchedule(sigId)(schedule)}
          handleDeleteSchedule={({ schedule }) => handleDeleteSchedule(sigId)(schedule)}
        />
      ),
      action: (
        <AddTimeEditor
          handleCreateSchedule={({ schedule }) => handleAddSchedule(sigId)(schedule)}
          schedulableType="DoseSig"
        />
      ),
    },
  ]

  const sortedAttributes = sortBy(sigAttributes, 'order')

  return (
    <ul className="list-none p-0 m-0 dose-order-fields">
      {map(sortedAttributes, ({
        label, currentValue, newValue, action,
        valueChangeHandler, editor, isEditing,
      }, index) => (
        <DoseAttributeRow
          key={label}
          label={label}
          valueChangeHandler={valueChangeHandler}
          currentValue={currentValue}
          newValue={newValue}
          action={action}
          editor={editor}
          isEditing={isEditing}
          backgroundHighlight={index % 2 === 0}
        />
      ))}
    </ul>
  )
}

export default SigFieldGroup
