import moment from 'moment'

// eslint-disable-next-line import/prefer-default-export
export const generateDateColumns = ({
  dateRange,
  marApprovals = null,
  statusTemplate,
  headerTemplate,
  width,
  fieldPrefix,
  rowSpan = 1,
  align = 'center',
}) => {
  const dateColumns = []

  const startDate = moment(dateRange.startDate)
  const dayCount = dateRange.endDate.diff(dateRange.startDate, 'days') + 1

  let currentDate = startDate.clone()
  for (let dayIndex = 0; dayIndex < dayCount; dayIndex += 1) {
    const currentDateClone = currentDate.clone()
    const formattedDate = moment(currentDate).format(dayCount > 7 ? 'D' : 'MMM Do')

    let dayMarApprovals = []
    if (marApprovals) {
      dayMarApprovals = marApprovals.filter((mar) => mar.days.includes(currentDateClone.format('YYYY-MM-DD')))
    }

    dateColumns.push({
      header: marApprovals
        ? headerTemplate(currentDateClone, formattedDate, dayMarApprovals)
        : headerTemplate(formattedDate),
      field: `${fieldPrefix}${currentDate.format('MMM-Do').toLowerCase()}`,
      body: (rowData) => statusTemplate(rowData, dayIndex),
      style: { minWidth: width, width, maxWidth: width },
      align,
      rowSpan,
    })
    currentDate = currentDate.add(1, 'days')
  }
  return dateColumns
}
