import React from 'react'
import { Tag } from 'primereact/tag'
import 'primeflex/primeflex.css'

function SkippedTag({
  skipCount, onClick,
}) {
  return (
    <Tag
      onClick={onClick}
      severity="warning"
      className="w-6rem cursor-pointer"
      value={`${skipCount} Skipped`}
      rounded
    />
  )
}

export default SkippedTag
