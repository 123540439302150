import { map } from 'lodash'
import { Badge } from 'primereact/badge'
import { classNames } from 'primereact/utils'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

/**
 * Component to render a tab navigator with links
 *
 * @param {Object} props - Component props
 * @param {Array} props.tabs - Array of tabs to render
 * @param {Object} props.tabs.linkTo - Link to navigate to
 * @param {Object} props.tabs.label - Label to display
 * @param {Object} props.tabs.badge - Badge to display
 * @param {Object} props.tabs.icon - Icon
 * @returns {React.Component} - Component
 */
function LinkTabNavigator({
  tabs,
}) {
  const location = useLocation()

  return (
    <div className="p-tabmenu">
      <div className="flex flex-row p-tabmenu-nav">
        {map(tabs, ({
          linkTo, label, badge, icon,
        }) => (
          <div
            className={classNames(
              'p-tabmenuitem cursor-pointer',
              {
                'p-highlight': location.pathname.includes(linkTo),
              },
            )}
            key={linkTo}
          >
            <Link to={linkTo} className="p-menuitem-link flex flex-row align-items-center gap-2">
              {badge && <Badge severity={badge.severity} value={badge.value} />}
              {icon
                      && <i className={`pi ${icon}`} />}
              <div className="p-menuitem-text">
                {label}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LinkTabNavigator
