import React from 'react'
import moment from 'moment-timezone'
import { momentFormats } from '@services/utils/moment'
import { Divider } from 'primereact/divider'
import { isEmpty } from 'lodash'
import ColoredCircle from '../ColoredCircle'
import { conditionCheckTakenAt, conditionCheckTakenBy } from './VitalDashboardUtils'

function ConditionCheckDetails({ conditionCheck, timezone }) {
  const { status, expectedAt, consumption } = conditionCheck
  const completedAt = conditionCheckTakenAt(conditionCheck)
  const takenBy = conditionCheckTakenBy(conditionCheck)
  return (
    <div className="flex flex-column row-gap-2 m-1">
      {
        status && (
          <div className="flex flex-row gap-1 align-items-center text-left">
            <ColoredCircle type={status} />
            <span className="text-base font-medium line-height-2">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
          </div>
        )
      }
      { expectedAt && (
      <div className="flex flex-row gap-1">
        <span className="text-xs">Expected:</span>
        <span className="text-xs font-medium">{ moment(expectedAt).tz(timezone).format(momentFormats.dateYearTime) }</span>
      </div>
      )}
      { completedAt && (
      <div className="flex flex-row gap-1">
        <div className="flex flex-row gap-1">
          <span className="text-xs">Completed:</span>
          <span className="text-xs font-medium">{ moment(completedAt).tz(timezone).format(momentFormats.dateYearTime) }</span>
          <span className="text-xs">by</span>
          <span className="text-xs font-medium">{takenBy?.fullName || 'Unspecified'}</span>
        </div>
      </div>
      )}
      {
        status === 'skipped' && consumption && (
          <div className="flex flex-row gap-1">
            <span className="text-xs">Skipped: </span>
            <span className="text-xs font-medium">{moment(consumption.confirmedAt).tz(timezone).format(momentFormats.dateYearTime)}</span>
            <span className="text-xs">by</span>
            <span className="text-xs font-medium">{consumption.confirmedBy?.fullName || 'Unspecified'}</span>
          </div>
        )
      }
    </div>
  )
}

/**
 * Overlay that displays the status of conditionChecks.
 *
 * @param {Object} conditionChecks - The selected cell containing details.
 * @param {string} timezone - The timezone of the organization.
 * @returns {JSX.Element} - JSX element representing the overlay.
 */
function StatusOverlay({
  conditionChecks,
  timezone,
}) {
  if (isEmpty(conditionChecks)) return null

  return (
    <div className="w-22rem flex flex-column">
      {
        conditionChecks.map((conditionCheck) => (
          <ConditionCheckDetails
            key={conditionCheck.id}
            conditionCheck={conditionCheck}
            timezone={timezone}
          />
        )).reduce((prev, curr) => [prev, <Divider />, curr])
      }
    </div>
  )
}

export default StatusOverlay
