import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import '../treatments-styles.scss'

const viewLinks = [
  {
    label: 'All',
    linkTo: 'all',
  },
  {
    label: 'Routine',
    linkTo: 'routine',
  },
  {
    label: 'PRN',
    linkTo: 'prn',
  },
]

// TODO: DRY when there is time
function Menu() {
  return (
    <div className="flex flex-row justify-space-between align-items-center pl-2 pr-4 py-3">
      <div className="flex flex-row align-items-center">
        { viewLinks.map((link) => (
          <NavLink to={link.linkTo} key={link.linkTo}>
            {
              ({ isActive }) => (
                <Button className={classNames('flex flex-row align-items-center p-button-sm p-button-outlined', { 'p-button-text': !isActive })}>
                  {!!link.badge
                    && (
                      <Badge severity={link.badge.severity} value={link.badge.value} />
                    )}
                  <span className={classNames({ 'text-600': !isActive, 'ml-2': !!link.badge })}>
                    {link.label}
                  </span>
                </Button>
              )
            }
          </NavLink>
        ))}
      </div>
      <Link to="create">
        <Button className="p-button-sm" label="Create Treatment" />
      </Link>
    </div>
  )
}

export default Menu
