import React, {
  useState, useMemo, useRef, useCallback,
} from 'react'
import { OverlayPanel } from 'primereact/overlaypanel'
import PRNConsumptionTable from '@components/display/Consumptions/PRNConsumptionTable'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { usePrnMarQuery } from '@hooks/patients/prnMarHooks'
import PRNStatusOverlay from './PRNStatusOverlay'
import PRNDoseFormModal from './PRNDoseFormModal'
import EmptyStateBody from './EmptyStateBody'
import { transformDataForTable, buildDateAndTimeWithTimeZone, generateDateColumns } from './prnConsumptionUtils'
import ConsumptionTag from './ComsumptionTag'
import ConsumptionTags from './ConsumptionTags'
import MarSigDetails from '../MarSigDetails'
import AdherenceTableHeader from '../AdherenceTableHeader'
import MARApprovalsTable from '../MARApproval/MARApprovalTable'
import ApprovalOverlay from '../MARApproval/ApprovalOverlay'

function PRNConsumptions({
  patientId,
  dateRange,
  marApprovals,
  isApprovalsLoading,
  maxDataViewHeight,
}) {
  const statusMessagesRef = useRef()
  const [overlayDetails, setOverlayDetails] = useState({})
  const [isPRNDialogVisible, seIsPRNDialogVisible] = useState(false)
  const [selectedDose, setSelectedDose] = useState({})
  const [selectedDoseSig, setSelectedDoseSig] = useState({})
  const [selectedDate, setSelectedDate] = useState(null)
  const [dialogMode, setDialogMode] = useState('add')
  const [consumptionToBeUpdated, setConsumptionToBeUpdated] = useState({})
  const [dateHovered, setDateHovered] = useState()

  const { timezone } = useCurrentOrganization()
  const { role: currentUserRole } = useCurrentUser()
  const isAdmin = currentUserRole === 'site_admin'
  const dateMarApprovals = dateHovered ? marApprovals.filter((mar) => mar.days.includes(dateHovered.format('YYYY-MM-DD'))) : []

  const overlayRef = useRef(null)
  const dateOverlayRef = useRef(null)
  const {
    data,
    isLoading: isLoadingPrnDose,
  } = usePrnMarQuery(patientId, dateRange, statusMessagesRef)

  const transformedData = useMemo(() => transformDataForTable(data, timezone), [data, timezone])

  const medicineTemplate = (rowData) => (
    <div className="flex flex-column gap-1 align-items-start text-left">
      <span style={{ wordBreak: 'break-all' }}>
        {rowData.medicineName}
      </span>
    </div>
  )

  const detailsTemplate = (rowData) => {
    const { doseSig } = rowData
    return (
      <MarSigDetails
        doseSig={doseSig}
      />
    )
  }

  const medColumn = [
    {
      header: 'Medicine',
      field: 'medicineName',
      style: { minWidth: '320px', width: '320px', maxWidth: '320px' },
      align: 'center',
      body: medicineTemplate,
    },
  ]
  const detailsColumn = [
    {
      field: 'doseSig.id', header: 'Details', style: { minWidth: '240px', width: '240px', maxWidth: '240px' }, body: detailsTemplate,
    },
  ]
  const openPRNDialog = (rowData, currentCellDate) => {
    setSelectedDate(currentCellDate)
    setSelectedDose(rowData.dose)
    setSelectedDoseSig(rowData.doseSig)
    setDialogMode('add')
    seIsPRNDialogVisible(true)
  }

  const handleEdit = useCallback((e, consumption) => {
    overlayRef.current.toggle(e)
    setDialogMode('edit')
    setConsumptionToBeUpdated({
      ...consumption,
      currentConfirmAt: buildDateAndTimeWithTimeZone({
        date: selectedDate,
        time: consumption.confirmedAt,
        timezone,
      }),
    })
    setOverlayDetails({})
    seIsPRNDialogVisible(true)
  }, [selectedDate])

  const showDateOverlay = (date, e) => {
    setDateHovered(date)
    if (dateOverlayRef.current) {
      dateOverlayRef.current.hide()
      requestAnimationFrame(() => {
        dateOverlayRef.current.show(e)
      })
    } else {
      dateOverlayRef.current.show(e)
    }
  }

  const hideDateOverlay = () => {
    if (dateOverlayRef.current) {
      dateOverlayRef.current.hide()
    }
  }

  const headerTemplate = (date, formattedDate, approvals) => (
    <AdherenceTableHeader
      date={date}
      formattedDate={formattedDate}
      approvals={approvals}
      showDateOverlay={showDateOverlay}
      hideDateOverlay={hideDateOverlay}
    />
  )

  const bodyTemplate = useCallback((rowData, column, currentCellDate) => {
    const { field } = column
    const prnStatusTags = []

    const handleOnClickStatus = (e) => {
      setOverlayDetails(rowData[column.field])
      setSelectedDate(currentCellDate)
      overlayRef.current.toggle(e)
    }

    ['confirmed', 'refused'].forEach((type) => {
      if (rowData[field]?.[type]) {
        prnStatusTags.push(
          <ConsumptionTag
            key={`${type}-${field}`}
            count={rowData[field][type].length}
            type={type}
          />,
        )
      }
    })

    const emptyStateBody = isAdmin ? (
      <EmptyStateBody
        rowData={rowData}
        currentCellDate={currentCellDate}
        onClick={openPRNDialog}
      />
    ) : null

    return prnStatusTags.length > 0
      ? (
        <ConsumptionTags
          prnStatusTags={prnStatusTags}
          handleOnClickStatus={handleOnClickStatus}
          openPRNDialog={openPRNDialog}
          rowData={rowData}
          currentCellDate={currentCellDate}
          isAdmin={isAdmin}
        />
      )
      : emptyStateBody ?? null
  }, [openPRNDialog])

  const dateColumns = useMemo(
    () => generateDateColumns(dateRange, bodyTemplate, headerTemplate, marApprovals),
    [dateRange, bodyTemplate],
  )

  return (
    <>
      <div className="flex flex-column gap-3 text-base text-900 overflow-x-auto" style={{ overflowX: 'auto' }}>
        <PRNConsumptionTable
          consumptions={transformedData}
          isLoadingPrnDose={isLoadingPrnDose}
          medColumn={medColumn}
          detailsColumn={detailsColumn}
          dateColumns={dateColumns}
          maxDataViewHeight={maxDataViewHeight}
        />
        <MARApprovalsTable
          marApprovals={marApprovals}
          isLoading={isApprovalsLoading}
        />
      </div>
      <OverlayPanel ref={overlayRef} className="dose-overlay" dismissable>
        <PRNStatusOverlay
          timezone={timezone}
          data={overlayDetails}
          {...(isAdmin && { handleEdit })}
        />
      </OverlayPanel>
      <OverlayPanel ref={dateOverlayRef} className="date-overlay">
        <ApprovalOverlay marApprovals={dateMarApprovals} />
      </OverlayPanel>
      {isPRNDialogVisible && (
        <PRNDoseFormModal
          visible={isPRNDialogVisible}
          onHide={seIsPRNDialogVisible}
          dose={selectedDose}
          doseSig={selectedDoseSig}
          selectedDate={selectedDate}
          patientId={patientId}
          mode={dialogMode}
          consumptionToBeUpdated={consumptionToBeUpdated}
          setConsumptionToBeUpdated={setConsumptionToBeUpdated}
        />
      )}
    </>
  )
}

export default PRNConsumptions
