import React from 'react'
import { useNavigate } from 'react-router-dom'
import { flatMap, get, first } from 'lodash'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import DoseTag from '@components/clientDoses/Header/DoseTag'
import Calendar, { formatSchedulesForDisplay } from '@components/display/Calendar/Calendar'
import { formatDoseAmount } from '@components/clientDoses/doseUtils'
import { timeTemplate } from '@services/utils'
import { useClientDoses } from '../clientDosesHooks'

function ScheduledDoses({ clientId, maxDataViewHeight }) {
  const navigate = useNavigate()
  const { data: doses, isLoading } = useClientDoses(clientId, { includeAll: false })

  const events = flatMap(
    doses,
    (dose) => formatSchedulesForDisplay({ schedules: dose.schedules, dose }),
  )

  const eventContent = ({ event, view }) => {
    const dose = get(event, 'extendedProps.dose')
    const doseSig = get(event, 'extendedProps.doseSig')
    // TODO: This smart medicine name formatting should be moved to the backend
    const medicineDisplayName = get(dose, 'brandName', first(dose.medicine?.name?.split('(')))

    const administrationAmountDisplay = formatDoseAmount({
      // TODO: Change to doseSig?.administrationAmount after backfilling dose sigs
      administrationAmount: get((doseSig || dose), 'administrationAmount'),
      // TODO: Change to doseSig?.units after backfilling dose sigs
      units: get((doseSig || dose), 'units'),
      medicine: get(dose, 'medicine'),
    })

    if (view.type === 'listWeek') {
      return (
        <div className="flex flex-row gap-1 text-xs font-normal align-items-start">
          <span>{`${administrationAmountDisplay}`}</span>
          <span className="font-semibold">{medicineDisplayName}</span>
          {
            dose?.medicine.originCode === 'rx_term' && (
              <span>{`(${dose.medicine.strength})`}</span>
            )
          }
          <span>{`expected at ${timeTemplate(event.extendedProps.expectedAt)}`}</span>
          {
            // TODO: Should also tag when the dose sig is paused once supported
            get(dose, 'currentPause') && (
              <DoseTag color="var(--bluegray-300)" text="On Hold" />
            )
          }
        </div>
      )
    }

    return (
      <div className="flex flex-column gap-1 text-xs font-normal">
        <div className="flex flex-row gap-1 align-items-center">
          <i className="fa-regular fa-clock" />
          <span>{timeTemplate(event.extendedProps.expectedAt)}</span>
          {
            // TODO: Should also tag when the dose sig is paused once supported
            get(dose, 'currentPause') && (
              <DoseTag color="var(--bluegray-300)" text="On Hold" />
            )
          }
        </div>
        <span>{medicineDisplayName}</span>
        <span>{administrationAmountDisplay}</span>
      </div>
    )
  }

  const dateNavigationControls = (calendar) => {
    const titleTemplate = get(calendar, 'data.viewTitle')
    return (
      <div className="flex flex-row gap-2 align-items-center">
        <span className="text-base font-normal ml-1">
          {titleTemplate}
        </span>
        <div className="flex flex-row">
          <Button
            icon="pi pi-chevron-left"
            onClick={calendar.handlePrevious}
            className="p-button-text p-button-sm p-button-secondary"
          />
          <Button
            icon="pi pi-chevron-right"
            onClick={calendar.handleNext}
            className="p-button-text p-button-sm p-button-secondary"
          />
        </div>
      </div>
    )
  }

  const controls = (calendar) => {
    const viewOptions = [
      {
        label: 'List',
        value: 'listWeek',
        icon: 'pi pi-list',
      },
      {
        label: 'Day',
        value: 'timeGridDay',
        icon: 'fa fa-calendar-day',
      },
      {
        label: 'Week',
        value: 'timeGridWeek',
        icon: 'fa fa-calendar-week',
      },
    ]

    const selectedViewTemplate = (option) => (
      <>
        <i className={`${option?.icon} mr-2`} />
        <span>{option?.label}</span>
      </>
    )

    return (
      <div className="flex flex-column gap-3 text-base text-900">
        <div className="flex flex-row justify-content-between align-items-center">
          <span className="text-lg flex flex-row gap-2 align-items-center">
            {dateNavigationControls(calendar)}
          </span>
          <Dropdown
            className="w-14rem"
            options={viewOptions}
            onChange={(e) => calendar.handleViewChange(e.value)}
            value={get(calendar, 'data.currentViewType')}
            valueTemplate={selectedViewTemplate}
          />
        </div>
      </div>
    )
  }

  const handleEventSelection = ({ event }) => (
    navigate(`../manage/${event.extendedProps.dose.id}`)
  )

  // TO DO: Add Loading Skeletons
  if (isLoading) {
    return 'Loading...'
  }

  return (
    <div className="fadein">
      <Calendar
        initialView="listWeek"
        height={maxDataViewHeight - 20}
        eventContent={eventContent}
        events={events}
        renderControls={controls}
        handleEventSelection={handleEventSelection}
      />
    </div>
  )
}

export default ScheduledDoses
