import React from 'react'
import { Tag } from 'primereact/tag'
import 'primeflex/primeflex.css'

function CompletedTag({
  value, onClick,
}) {
  return (
    <Tag
      className="w-6rem cursor-pointer"
      value={value}
      onClick={onClick}
      rounded
    />
  )
}

export default CompletedTag
