import React from 'react'
import moment from 'moment'
import CustomTag from '@components/display/CustomTag'
import { TreatmentCategoryTag } from '@components/treatments/tags'
import { momentFormats } from '@services/utils/moment'

function TreatmentAttribute({ label, value }) {
  return (
    <div key={label} className="flex flex-row align-items-center">
      <span className="mr-1 text-600">
        {label}
        :
      </span>
      <span>{value}</span>
    </div>
  )
}

function TreatmentDetails({ treatment }) {
  const {
    onboardedAt, orderNumber, pharmacyInstructions, startAt, endAt,
  } = treatment

  const startDate = startAt ? moment(startAt).format(momentFormats.dateYear) : 'N/A'
  const endDate = endAt ? moment(endAt).format(momentFormats.dateYear) : 'N/A'

  const directiveText = onboardedAt ? 'Treatment Update' : 'New Treatment'

  return (
    <div className="flex flex-column col-12 p-4 gap-2">
      <div className="flex flex-row gap-2">
        <div className="flex flex-row gap-2">
          <CustomTag color="var(--green-400)" text={directiveText} />
          <TreatmentCategoryTag treatment={treatment} />
        </div>
      </div>
      <div className="flex flex-column gap-2 text-sm text-900 p-1">
        <div>
          <span className="p-0 mr-1 text-600">Instructions:</span>
          <span>{pharmacyInstructions}</span>
        </div>
        <div className="flex flex-wrap gap-2">
          <TreatmentAttribute label="Order Number" value={orderNumber} />
          <TreatmentAttribute label="Start At" value={startDate} />
          <TreatmentAttribute label="End At" value={endDate} />
        </div>
      </div>
    </div>
  )
}

export default TreatmentDetails
