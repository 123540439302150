import { useCurrentOrganization, useCurrentUser } from '@components/App'
import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Button } from 'primereact/button'
import { usePatientConsumptions } from '../../../features/Patients/patientHooks'
import { getFormattedDoseConsumptions, unknownStatusConsumptions } from './adherenceDashboardUtils'
import MarApprovalDialog from './MAR/MARApproval/MarApprovalDialog'
import Menu from './AdherenceMenu'
import ScheduledConsumptions from './MAR/ScheduledDoses/ScheduledConsumptions'
import PRNConsumptions from './MAR/PrnDoses/PRNConsumptions'

function TypeNavigator({ granularity, setApprovalDialogVisible }) {
  const currentUser = useCurrentUser()
  return (
    <div className="flex flex-row gap-3 align-items-center w-full">
      <Menu />
      <div className="flex-grow-1" />
      {
        // At the moment we only want to approve monthly MARs because this is
        // they way users are using the system. This may change in the future,
        // in backend we support every granularity.
        currentUser.role === 'site_admin' && granularity === 'Monthly' && (
          <Button
            label="Approve"
            className="p-button-primary"
            onClick={() => setApprovalDialogVisible(true)}
          />
        )
      }
    </div>
  )
}

function MAR({
  patientId,
  startDate,
  endDate,
  totalDays,
  maxDataViewHeight,
  marApprovals,
  marApprovalsLoading,
  granularity,
}) {
  const dateRange = { startDate, endDate }
  const [approvalDialogVisible, setApprovalDialogVisible] = useState(false)
  const currentOrganization = useCurrentOrganization()
  const {
    data: consumptions,
    isLoading: doseConsumptionsLoading,
  } = usePatientConsumptions(patientId, dateRange, { excludePRN: true })

  const formattedConsumptions = getFormattedDoseConsumptions({
    consumptions,
    timezone: currentOrganization.timezone,
    startDate,
    totalDays,
  })

  useEffect(() => {
    const result = unknownStatusConsumptions(consumptions)
    if (result.unknownStatus.length === 0) return

    Sentry.captureMessage('Unknown status found in consumptions', {
      extra: result,
    })
  }, [patientId, consumptions.length])

  return (
    <div className="flex flex-column gap-3">
      <MarApprovalDialog
        visible={approvalDialogVisible}
        onHide={() => setApprovalDialogVisible(false)}
        patientId={patientId}
        currentStart={startDate}
        currentEnd={endDate}
        existingMarApprovals={marApprovals}
        timezone={currentOrganization.timezone}
      />
      <TypeNavigator
        granularity={granularity}
        setApprovalDialogVisible={setApprovalDialogVisible}
      />
      <Routes>
        <Route
          index
          element={(
            <Navigate
              to={currentOrganization.ekitsEnabled ? 'prn' : 'schedule'}
              replace
            />
          )}
        />
        <Route
          path="schedule"
          element={(
            <ScheduledConsumptions
              isLoading={doseConsumptionsLoading}
              data={formattedConsumptions}
              dateRange={dateRange}
              timezone={currentOrganization.timezone}
              patientId={patientId}
              marApprovals={marApprovals}
              isLoadingMarApprovals={marApprovalsLoading}
              startDate={startDate}
            />
           )}
        />
        <Route
          path="prn"
          element={(
            <PRNConsumptions
              patientId={patientId}
              dateRange={dateRange}
              timezone={currentOrganization.timezone}
              maxDataViewHeight={maxDataViewHeight}
              marApprovals={marApprovals}
              isLoadingPrnDose={doseConsumptionsLoading}
            />
          )}
        />
      </Routes>
    </div>
  )
}

export default MAR
