import moment from 'moment-timezone'
/**
 * @typedef {moment.Moment} Moment
 */

export const momentFormats = {
  date: 'YYYY-MM-DD',
  dateYear: 'MMM Do, YYYY',
  monthShortDay: 'MMM Do',
  onlyDay: 'D',
  dateYearTime: 'MMM Do, YYYY hh:mm A',
  time_12: 'hh:mm A',
  time_12_trim: 'h:mm A',
  time_24: 'HH:mm',
  datetime: 'YYYY-MM-DD HH:mm:ss ZZ',
  datetime_12: 'YYYY-MM-DD hh:mm A',
  datetime_24: 'YYYY-MM-DD HH:mm',
  iso8601: 'YYYY-MM-DD HH:mm:ss',
  iso8601_utc: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  monthDay: 'MMM Do, YYYY',
}

/**
 * Parse iso8601 string to moment object e.g. '2020-01-01 12:00:00'
 * By default it will use the timezone of the browser
 *
 * @param {string} time
 * @returns {Moment}
 * @example
 * momentFromIso8601Str('2020-01-01 12:00:00')
*/
export function momentFromIso8601Str(time) {
  return moment(time, momentFormats.iso8601)
}

/**
 * Convert moment object to iso8601 string without timezone e.g. '2020-01-01 12:00:00'
 * @param {Moment} time
 * @returns {string}
 * @example
 * const time = momentFromIso8601Str('2020-01-01 12:00:00')
 * momentToIso8601Str(time) // '2020-01-01 12:00:00'
*/
export function momentToIso8601Str(time) {
  return time.format(momentFormats.iso8601)
}

/**
 * Convert moment object to iso8601 string with UTC timezone e.g. '2020-01-01T12:00:00Z'
 * Without converting the time, this means the time will be the same but the timezone will be UTC
 * @param {Moment} time
 * @returns {string}
 * @example
 * const time = momentFromIso8601Str('2020-01-01 12:00:00')
 * momentToIso8601StrUtc(time) '2020-01-01T12:00:00Z'
*/
export function momentToIso8601StrUtc(time) {
  return time.format(momentFormats.iso8601_utc)
}

/**
 * Convert moment object to local time without changing the time
 * @param {Moment} momentTime
 * @param {string} timezone
 * @returns {Moment}
 * @example
 * const time = momentFromIso8601Str('2020-01-01 12:00:00')
 * momentTzWihtoutChangingTime(time, 'America/New_York') // moment -> '2020-01-01 12:00:00 -0500'
 * momentTzWihtoutChangingTime(time, 'America/Los_Angeles') // moment -> '2020-01-01 12:00:00 -0800'
*/
export const momentTzWihtoutChangingTime = (momentTime, timezone) => {
  if (!momentTime) return null
  return momentTime
    .clone().tz(timezone, true)
}

/**
 * Convert moment object to local time without changing the time, if timezone is provided
 * first we convert the moment to the timezone changing the time and then we convert it to
 * local time without changing the time
 * @param {Moment} momentTime
 * @param {string} timezone
 * @returns {Moment}
*/
export const momentoToLocalWithoutChangingTime = (momentTime, timezone = null) => {
  if (!momentTime) return null
  if (!timezone) {
    return momentTime.clone().local(true)
  }
  return momentTime.clone().tz(timezone).local(true)
}

/**
 * Convert moment object to local time without changing the time, if timezone is provided
 * We are going to set the timezone without changing the time, if is not provided we are going to
 * use the browser timezone
 * @param {Moment} momentTime
 * @param {string} timezone
 * @returns {Moment}
*/
export const momentFromMomentDateAndTime = (momentDate, momentTime, timezone = null) => {
  const dateStr = momentDate.format('YYYY-MM-DD')
  const timeStr = momentTime.format('HH:mm:ss')
  if (!timezone) {
    return momentFromIso8601Str(`${dateStr} ${timeStr}`)
  }
  return momentFromIso8601Str(`${dateStr} ${timeStr}`).tz(timezone, true)
}

/**
 * This function takes an integer representing the total number of minutes into a day
 * and returns a moment object for the current day with the hours and minutes set.
 * @param {number} totalMinutes
 */
export const getMomentFromMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60)
  const mins = totalMinutes % 60

  return moment().startOf('day').hour(hours).minute(mins)
}

/**
 * This function takes an integer representing the total number of minutes into a day
 * and returns a formatted time string in the optional format.
 * @param minutes
 * @param {string} format
 * @returns {*|string}
 */
export const getDisplayTimeFromMinutes = (minutes, format = momentFormats.time_12_trim) => {
  if (moment.isMoment(minutes)) {
    return minutes.format(format)
  }

  if (typeof minutes !== 'number') {
    return 'Unknown time'
  }

  return getMomentFromMinutes(minutes).format(format)
}

/**
 * This function returns a moment in the middle of the moment range.
 *
 * @param {Moment} startDate
 * @param {Moment} endDate
 * @returns {Moment}
 */
export const getMiddleMoment = (start, end) => start.clone().add(end.diff(start) / 2, 'ms')
