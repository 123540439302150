import React from 'react'
import { Link } from 'react-router-dom'
import { DataView } from 'primereact/dataview'
import { Divider } from 'primereact/divider'
import { useReviewableTreatments } from '@hooks/treatments'
import TreatmentDetails from './TreatmentDetails'

function ReviewableTreatment(patientId, treatment) {
  // eslint-disable-next-line react/destructuring-assignment
  const treatmentLink = `/admin/patients/${patientId}/treatments/manage/${treatment.id}`

  return (
    <Link to={treatmentLink} className="w-full cursor-pointer hover:bg-blue-50">
      <TreatmentDetails treatment={treatment} />
      <Divider className="my-0" />
    </Link>
  )
}

export function ReviewableTreatmentsList({ patientId }) {
  const { data: treatments, isLoading } = useReviewableTreatments(patientId)

  return (
    <div className="flex flex-column">
      <DataView
        value={treatments}
        itemTemplate={(treatment) => ReviewableTreatment(patientId, treatment)}
        loading={isLoading}
      />
    </div>
  )
}

export default ReviewableTreatmentsList
