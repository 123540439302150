import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import moment from 'moment'
import { Messages } from 'primereact/messages'
import { Calendar } from 'primereact/calendar'
import { useCurrentOrganization } from '@components/App'
import { MultiSelect } from 'primereact/multiselect'
import useExportVitalSignsReport from '@hooks/reports/useExportVitalSignsReport'

const vitalOptions = [
  { label: 'Temperature', value: 'TEMPERATURE' },
  { label: 'Pulse', value: 'PULSE' },
  { label: 'Oxygen Saturation', value: 'BLOOD_OXYGEN' },
  { label: 'Blood Pressure', value: 'BLOOD_PRESSURE' },
]

function VitalSignsReportDialog({ patientId, isVisible, setIsVisible }) {
  const organization = useCurrentOrganization()
  const statusMessageRef = useRef(null)
  const {
    mutateAsync: exportPatientVitalSigns,
    isLoading: isExporting,
  } = useExportVitalSignsReport(statusMessageRef, patientId)

  const [selectedVitals, setSelectedVitals] = useState([])
  const [startDate, setStartDate] = useState(moment().startOf('day').toDate())
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate())

  const handleStartDateChange = (e) => {
    const newStartDate = e.value
    setStartDate(newStartDate)
    if (endDate && moment(newStartDate).isAfter(endDate)) {
      setEndDate(newStartDate)
    }
  }

  const handleEndDateChange = (e) => {
    const newEndDate = e.value
    setEndDate(newEndDate)
    if (startDate && moment(newEndDate).isBefore(startDate)) {
      setStartDate(newEndDate)
    }
  }

  const handleExportPatientVitalSignsReport = async () => {
    exportPatientVitalSigns({
      startDate, endDate, vitalCodes: selectedVitals,
    }, {
      onSuccess: () => setIsVisible(false),
    })
  }

  const handleVitalsChange = (e) => setSelectedVitals(e.value)

  const isExportDisabled = selectedVitals.length === 0

  return (
    <Dialog
      header={`Export ${organization.patientLabelSingular} Vital Signs Report`}
      visible={isVisible}
      onHide={() => setIsVisible(false)}
    >
      <Messages ref={statusMessageRef} />
      <div className="flex flex-column">
        <label htmlFor="font-normal">Start Date</label>
        <Calendar
          className="mt-1 mb-3 border-round-md"
          value={startDate}
          stepMinute={15}
          onChange={handleStartDateChange}
          showTime={false}
          showIcon
          icon={<i className="pi pi-clock" />}
        />
        <label htmlFor="font-normal">End Date</label>
        <Calendar
          className="mt-1 mb-3 border-round-md"
          value={endDate}
          stepMinute={15}
          onChange={handleEndDateChange}
          showTime={false}
          showIcon
          icon={<i className="pi pi-clock" />}
        />
        <label htmlFor="font-normal">Vital Signs</label>
        <MultiSelect
          value={selectedVitals}
          options={vitalOptions}
          onChange={handleVitalsChange}
          optionLabel="label"
          placeholder="Select vitals"
          className="mt-1 mb-3 border-round-md"
        />
        <Button
          className="mt-1 p-button-sm p-button-outlined"
          label="Export"
          onClick={handleExportPatientVitalSignsReport}
          loading={isExporting}
          disabled={isExportDisabled}
        />
      </div>
    </Dialog>
  )
}

export default VitalSignsReportDialog
