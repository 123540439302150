import { displayErrorMessages } from '@services/errorHandler'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { get, map } from 'lodash'
import { useMemo } from 'react'

// eslint-disable-next-line import/prefer-default-export
export function usePrnMarQuery(patientId, dateRange, statusMessage) {
  const query = useQuery({
    queryKey: ['prnMar', patientId, dateRange],
    queryFn: () => {
      const params = {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
      }
      return ApiClient.get(`/patients/${patientId}/prn_mar`, { params })
    },
    onError: (error) => statusMessage.current && displayErrorMessages(error, statusMessage),
  })

  return useMemo(() => (
    {
      ...query,
      data: map(get(query, 'data'), (consumption) => (consumption)),
    }
  ), [query, dateRange])
}
