import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import Menu from './Menu'
import TreatmentList from './TreatmentList'
import '../treatments-styles.scss'

const wrapper = (component) => (
  <div className="p-4">
    {component}
  </div>
)

function TreatmentsOverviewPage({ clientId }) {
  return (
    <div className="col-12 treatments">
      <Card className="h-full treatment-card-body">
        <div className="flex flex-column text-base text-900">
          <Menu />
          <Divider className="my-0" />
          <Routes>
            <Route
              path="all"
              element={wrapper(<TreatmentList clientId={clientId} />)}
            />
            <Route
              path="routine"
              element={
                wrapper(<TreatmentList clientId={clientId} filters={[{ name: 'treatmentType', value: 'routine' }]} />)
              }
            />
            <Route
              path="prn"
              element={
                wrapper(<TreatmentList clientId={clientId} filters={[{ name: 'treatmentType', value: 'prn' }]} />)
              }
            />
          </Routes>
        </div>
      </Card>
    </div>
  )
}

export default TreatmentsOverviewPage
