import React, { useRef, useState } from 'react'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { isEmpty } from 'lodash'
import TaskConditionDialog from './TaskConditionDialog'
import TaskConditionsTable from './TaskConditionsTable'
import './task-conditions.scss'

export function TaskConditionsCard({ task }) {
  const statusMessage = useRef(null)
  const [selectedCondition, setSelectedCondition] = useState(false)
  const [showCreateConditionDialog, setShowCreateConditionDialog] = useState(false)

  if (isEmpty(task)) {
    return null
  }

  return (
    <div className="col-12 task-conditions">
      <TaskConditionDialog
        schedules={task.schedules}
        task={task}
        statusMessage={statusMessage}
        selectedCondition={selectedCondition}
        setSelectedCondition={setSelectedCondition}
        showCreateConditionDialog={showCreateConditionDialog}
        setShowCreateConditionDialog={setShowCreateConditionDialog}
      />
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-lg">
          <div className="flex flex-row justify-content-between align-items-center">
            <div>Criteria / Vitals</div>
            <Button
              label="Add Criteria / Vitals"
              icon="fa-solid fa-ruler"
              className="p-button-sm p-button-info"
              onClick={() => setShowCreateConditionDialog(true)}
            />
          </div>
        </div>
        <TaskConditionsTable
          task={task}
          setSelectedCondition={setSelectedCondition}
        />
      </Card>
    </div>
  )
}

export default TaskConditionsCard
