import React from 'react'
import { Toast } from 'primereact/toast'
import CreateTaskConditionDialog from './CreateTaskConditionDialog'
import EditTaskConditionDialog from './EditTaskConditionDialog'

function TaskConditionDialog({
  schedules,
  task,
  statusMessage,
  selectedCondition,
  setSelectedCondition,
  showCreateConditionDialog,
  setShowCreateConditionDialog,
}) {
  if (!task) {
    return null
  }

  return (
    <>
      <Toast ref={statusMessage} />
      {
        selectedCondition && (
          <EditTaskConditionDialog
            visible
            condition={selectedCondition}
            statusMessage={statusMessage}
            schedules={schedules}
            existingConditions={task.conditions}
            onHide={() => setSelectedCondition(null)}
            isPrn={task.taskType === 'prn'}
          />
        )
      }
      {
        showCreateConditionDialog && (
          <CreateTaskConditionDialog
            visible
            task={task}
            statusMessage={statusMessage}
            schedules={schedules}
            existingConditions={task.conditions}
            onHide={() => setShowCreateConditionDialog(false)}
          />
        )
      }
    </>
  )
}

export default TaskConditionDialog
