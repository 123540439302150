import React from 'react'
import CustomTag from '@components/display/CustomTag'

export function TreatmentCategoryTag({ treatment }) {
  if (!treatment) {
    return null
  }

  // TODO: Color should be based on category (e.g. gray for unknown)
  return <CustomTag color="var(--blue-400)" text={treatment.categoryFriendlyName} />
}

export default TreatmentCategoryTag
