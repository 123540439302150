import React from 'react'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import {
  NavLink, Route, Routes,
} from 'react-router-dom'
import { classNames } from 'primereact/utils'
import { Divider } from 'primereact/divider'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import Exports from '@components/patients/Exports'
import PatientPharmacyOrders from '@components/patients/Orders/PatientPharmacyOrders'
import ReviewableTreatmentsList from '@components/treatments/ReviewableTreatmentsList/ReviewableTreatmentsList'
import { Badge } from 'primereact/badge'
import PharmacyOrders from '../ClientDoses/PharmacyOrders'

function Menu({
  numReviewablePrescriptions, numReviewableTreatments, showRawOrders, showTreatments,
}) {
  const viewLinks = [
    {
      label: 'Prescriptions',
      linkTo: 'needs-review',
      badge: numReviewablePrescriptions && { severity: 'warning', value: numReviewablePrescriptions },
    },
  ]

  if (showTreatments) {
    viewLinks.push({
      label: 'Treatments',
      linkTo: 'treatments',
      badge: numReviewableTreatments && { severity: 'warning', value: numReviewableTreatments },
    })
  }

  if (showRawOrders) {
    viewLinks.push({
      label: 'Raw Orders',
      linkTo: 'raw_orders',
    })
  }

  return (
    <div className="flex flex-row justify-space-between align-items-center px-1">
      <div className="flex flex-row">
        {viewLinks.map((link) => (
          <NavLink to={link.linkTo} key={link.linkTo}>
            {
              ({ isActive }) => (
                <Button className={classNames('flex flex-row align-items-baseline p-button-sm p-button-outlined', { 'p-button-text': !isActive })}>
                  {!!link.badge
                    && (
                      <Badge severity={link.badge.severity} value={link.badge.value} />
                    )}
                  <span className={classNames({ 'text-600': !isActive, 'ml-2': !!link.badge })}>
                    {link.label}
                  </span>
                </Button>
              )
            }
          </NavLink>
        ))}
      </div>
    </div>
  )
}

function Orders({ patient, handleSetHeader }) {
  const currentUser = useCurrentUser()
  const { treatmentsEnabled } = useCurrentOrganization()

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  if (!patient) {
    return null
  }

  return (
    <div className="col-12">
      <Exports
        patientId={patient?.id}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      <Card className="h-full">
        <div className="flex flex-column gap-3 text-base text-900">
          <Menu
            numReviewablePrescriptions={patient?.numReviewablePrescriptions}
            numReviewableTreatments={patient?.numReviewableTreatments}
            showRawOrders={currentUser.role === 'superadmin'}
            showTreatments={treatmentsEnabled}
          />
          <Divider className="my-0" />
          <Routes>
            <Route
              path="needs-review/*"
              element={<PharmacyOrders patientId={patient.id} />}
            />
            {
              treatmentsEnabled && (
                <Route
                  path="treatments"
                  element={
                    <ReviewableTreatmentsList patientId={patient.id} />
                  }
                />
              )
            }
            {
              currentUser.role === 'superadmin' && (
                <Route
                  path="raw_orders"
                  element={
                    <PatientPharmacyOrders patientId={patient.id} />
                  }
                />
              )
            }
          </Routes>
        </div>
      </Card>
    </div>
  )
}

export default Orders
