import React, { useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import FormWrapper from '@components/display/Form/FormWrapper'
import { TaskForm } from '@components/tasks/TaskForm'
import TreatmentTable from '@components/treatments/TreatmentTable'
import { useCreateTask } from '@hooks/tasks'
import { useTreatment } from '@hooks/treatments'

function CreateTaskPage({ clientId }) {
  const statusMessage = useRef(null)
  const navigate = useNavigate()
  const { treatmentId } = useParams()

  const [task, setTask] = useState({
    taskType: 'prn',
    taskAction: 'binary',
    startAt: '',
    endAt: '',
    title: '',
    description: '',
    note: '',
    showOnTar: true,
  })

  const {
    data: treatment,
    isLoading: treatmentIsLoading,
  } = useTreatment({ treatmentId })

  const {
    mutateAsync: createTask,
    isLoading,
  } = useCreateTask({ statusMessage })

  if (treatmentId && treatmentIsLoading) {
    return 'Loading...'
  }

  const handleCreateTask = async () => {
    const createdTask = await createTask({
      ...task,
      patientId: clientId,
      treatmentId,
      taskCategory: treatmentId ? 'treatment' : 'custom',
      // TODO: Add the following to the create form
      showOnTar: true,
    })

    if (createdTask) {
      if (treatmentId) {
        navigate(`/admin/patients/${clientId}/treatments/manage/${treatmentId}`)
      } else {
        navigate(`/admin/patients/${clientId}/tasks/manage/${createdTask.id}`)
      }
    }
  }

  return (
    <div className="flex flex-row flex-wrap">
      <Toast ref={statusMessage} />
      <div className="col-8 xl:col-6">
        <FormWrapper title="Create Task" fullHeight>
          <TaskForm
            task={task}
            setTask={(value) => setTask((t) => ({ ...t, ...value }))}
          />
          <Button
            label="Create Task"
            className="p-button-sm mt-4"
            loading={isLoading}
            onClick={handleCreateTask}
          />
        </FormWrapper>
      </div>
      {
        treatmentId && treatment && (
          <TreatmentTable
            className="col-4 xl:col-6"
            treatment={treatment}
          />
        )
      }
    </div>
  )
}

export default CreateTaskPage
