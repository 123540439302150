import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'
import { Messages } from 'primereact/messages'
import AttributeCard, { Attribute } from '@components/display/AttributeCard'
import PrescriptionsTable from '@components/prescriptions/PrescriptionsTable'
import SuperAdminCard from '@components/display/SuperAdminCard'
import { dateTemplate } from '@services/utils'

function Details({ pharmacy, isLoading }) {
  const title = { label: 'Details', icon: 'pi-user' }
  const {
    name, createdAt, updatedAt, externalId,
  } = pharmacy || {}

  const valueTemplate = (value) => (
    isLoading ? <Skeleton className="inline-flex" width="5rem" height="1rem" /> : value
  )

  return (
    <AttributeCard title={title} direction="horizontal">
      { name
        && (
        <Attribute
          label="Name:"
          value={valueTemplate(name || 'Empty')}
        />
        )}
      { externalId
        && (
        <Attribute
          label="External ID:"
          value={valueTemplate(externalId || 'Empty')}
        />
        )}
      <Attribute
        label="Created:"
        value={valueTemplate(dateTemplate(createdAt))}
      />
      <Attribute
        label="Updated:"
        value={valueTemplate(dateTemplate(updatedAt))}
      />
    </AttributeCard>
  )
}

function PharmacyDetails({
  rootPath,
  pharmacyId,
  pharmacy,
  pharmacyLoading,
  handleSetHeader,
}) {
  const statusMessage = useRef()

  const primaryAction = (
    <div className="flex flex-row gap-2">
      <Link to={`${rootPath}/${pharmacyId}/update`}>
        <Button className="p-button-sm" label="Edit Pharmacy" />
      </Link>
    </div>
  )

  useEffect(() => handleSetHeader({ primaryAction }), [pharmacy])

  return (
    <div className="flex flex-row flex-wrap">
      <Messages ref={statusMessage} />
      <div className="col-12">
        <Details pharmacy={pharmacy} isLoading={pharmacyLoading} />
      </div>
      <div className="col-12">
        <SuperAdminCard title="Prescriptions">
          <PrescriptionsTable model="Pharmacy" id={pharmacyId} />
        </SuperAdminCard>
      </div>
    </div>
  )
}

export default PharmacyDetails
