import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export const useTreatment = ({ treatmentId }) => {
  const query = useQuery({
    queryKey: ['treatment', treatmentId],
    queryFn: () => ApiClient.get(`/treatments/${treatmentId}`),
    enabled: !!treatmentId,
  })

  return useMemo(() => ({
    ...query,
    data: query.data || {},
  }), [query])
}

export default useTreatment
