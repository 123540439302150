import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

export const useAcknowledgeTreatment = ({ statusMessage }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (treatment) => ApiClient.post(`/treatments/${treatment.id}/acknowledge`),
    onSuccess: async () => {
      await queryClient.invalidateQueries('treatment')
      await queryClient.invalidateQueries('treatments')
      statusMessage.current.clear()
      statusMessage.current.show([
        { severity: 'success', summary: 'Treatment Acknowledged!' },
      ])
    },
  })
}

export default useAcknowledgeTreatment
