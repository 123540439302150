import { displayErrorMessages } from '@services/errorHandler'
import { useQuery } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'

// eslint-disable-next-line import/prefer-default-export
export function useVitalsDashboardQuery(patientId, dateRange, statusMessage) {
  const query = useQuery({
    queryKey: ['vitalsDashboard', patientId, dateRange],
    queryFn: () => {
      const params = {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
      }
      return ApiClient.get(`/patients/${patientId}/vitals_dashboard`, { params })
    },
    onError: (error) => statusMessage.current && displayErrorMessages(error, statusMessage),
  })

  return query
}
